import { useQuery } from 'react-query';
import {
  BookAnAppointmentRequest,
  BookAnAppointmentResponse,
  CheckUserPendingReviewRequest,
  CheckUserPendingReviewResponse,
  GetConsultationsRequest,
  GetConsultationsResponse,
  GetCurrentUserAppointmentsResponse,
  LeaveReviewRequest,
  LeaveReviewResponse,
  RescheduleAppointmentRequest,
  RescheduleAppointmentResponse,
  SkipReviewRequest,
  SkipReviewResponse
} from '@vaza-eu/shoelessly';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import { getAuth } from 'firebase/auth';
import { useCloudFunctionMutation } from '../helpers/use-cloud-function-mutation';

export const useBookAnAppointmentMutation = () =>
  useCloudFunctionMutation<BookAnAppointmentRequest, BookAnAppointmentResponse>('bookAnAppointment');

export const useAppointmentQuery = (req: GetConsultationsRequest) => {
  const { data, ...rest } = useQuery(
    ['appointment', req.id],
    async (): Promise<GetConsultationsResponse> => {
      const cloudFn = httpsCallable(functions, 'getAppointment');
      const { data } = await cloudFn({ ...req });
      return data as GetConsultationsResponse;
    }, {
      enabled: !!req.id
    });

  return { data, ...rest };
};

export const usePendingReviewsQuery = (req: CheckUserPendingReviewRequest) => {
  const auth = getAuth();

  const { data, ...rest } = useQuery(
    ['pending-reviews'],
    async (): Promise<CheckUserPendingReviewResponse> => {
      const cloudFn = httpsCallable(functions, 'checkUserPendingReview');
      const { data } = await cloudFn();
      return data as CheckUserPendingReviewResponse;
    }, {
      enabled: !!auth.currentUser?.uid
    });

  return { data, ...rest };
};

export const useSkipAppointmentReviewMutation = () =>
  useCloudFunctionMutation<SkipReviewRequest, SkipReviewResponse>('skipAppointmentReview');

export const useLeaveAppointmentReviewMutation = () =>
  useCloudFunctionMutation<LeaveReviewRequest, LeaveReviewResponse>('leaveAppointmentReview');

export const useMyAppointmentsQuery = () => {
  const auth = getAuth();

  const { data, ...rest } = useQuery(
    ['my-appointments'],
    async (): Promise<GetCurrentUserAppointmentsResponse> => {
      const cloudFn = httpsCallable(functions, 'getCurrentUserAppointments');
      const { data } = await cloudFn();
      return data as GetCurrentUserAppointmentsResponse;
    }, {
      enabled: !!auth.currentUser?.uid
    });

  return { data, ...rest };
};

export const useRescheduleAppointmentMutation = () =>
  useCloudFunctionMutation<RescheduleAppointmentRequest, RescheduleAppointmentResponse>('rescheduleAppointment');