import type { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import Head from 'next/head';
import Script from 'next/script';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppProvider } from '../context/AppProvider/AppProvider';
import { GlobalStyle } from '../lib/styles/globals';
import { QUERY_CLIENT_CONFIG } from '@vaza-eu/vaza';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/sk';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useRouter } from '../lib/hooks/use-router';

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.locale('sk');
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1,
});

const METADATA = {
  sk: {
    title: 'Shoelessly – Konzultujte lekára online | Z pohodlia vášho domova',
  },
  en: {
    title: 'Shoelessly – Consult a doctor online | From the comfort of your home',
  }
}

export default function App({ Component, pageProps }: AppProps) {
  const { locale } = useRouter();

  const queryClient = useMemo(() => new QueryClient(QUERY_CLIENT_CONFIG), []);
  const metadata = useMemo(() => {
    if (locale === 'en') return METADATA.en;
    return METADATA.sk;
  }, [locale]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools/>
        <Hydrate state={pageProps.dehydratedState}>
          <AppProvider translationType={pageProps.translationType}>
            <Head>
              <title>{metadata.title}</title>
              <meta name="title" content={metadata.title}/>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
              <meta charSet="utf-8"/>
              <link rel="icon" href="/favicon-32x32.png" sizes="32x32"/>
            </Head>
            <Script id="ff-fouc-fix" strategy={'beforeInteractive'}
                    dangerouslySetInnerHTML={{ __html: 'var FF_FOUC_FIX;' }}/>
            <GlobalStyle/>
            <Component {...pageProps} />
            <ToastContainer/>
          </AppProvider>
        </Hydrate>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}
