import React from 'react';
import dynamic from 'next/dynamic';
import { StyledWrapper } from './Editor.styled';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

type EditorProps = {
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
};

export const Editor = ({ value, setValue, disabled }: EditorProps) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{
        'color': [
          '#ffffff',
          '#FED431',
          '#00ff00',
          '#ff3f3f',
          '#e31337',
          '#60cffd',
          '#2936ff',
          '#e3e3e3',
          '#c0c0c0',
          '#9f9f9f',
          '#737373',
          '#444444',
          '#282828',
          '#000000',
        ]
      }],
    ],
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'color',
  ];

  return (
    <StyledWrapper>
      <ReactQuill
        value={value}
        onChange={val => setValue(val)}
        modules={modules}
        formats={formats}
        readOnly={disabled}
      />
    </StyledWrapper>
  );
};