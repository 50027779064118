import { useQuery } from 'react-query';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useRouter } from '../../lib/hooks/use-router';
import { TranslationProps, TranslationTypesEnum } from '../../lib/types/translation-types-enum';

export const fetchTranslations = async (type: TranslationProps['type'], locale: string | undefined) => {
  const documentSnapshot = await getDoc(doc(db, 'translations', `${locale}-${type}`));

  if (!documentSnapshot.exists()) {
    return {};
  }

  return documentSnapshot.data();
};

export const useTranslationsQuery = (type = TranslationTypesEnum.Main) => {
  const { locale } = useRouter();
  return useQuery(['translations', `${locale}-${type}`], () => fetchTranslations(type, locale), {
    enabled: !!locale,
  });
};
