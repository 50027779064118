import { GetCurrentUserRequest, GetCurrentUserResponse } from '@vaza-eu/shoelessly';
import { useQuery } from 'react-query';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import { getAuth } from 'firebase/auth';

export const useGetCurrentUserQuery = () => {
  const auth = getAuth();

  const { data, ...rest } = useQuery<GetCurrentUserResponse, unknown>(
    ['getCurrentUser'],
    async () => {
      const cloudFn = httpsCallable<GetCurrentUserRequest, GetCurrentUserResponse>(functions, 'getCurrentUser');
      const response = await cloudFn();
      return response.data;
    },
    {
      enabled: !!auth.currentUser,
    }
  );

  return {
    data: (data || null) as GetCurrentUserResponse,
    ...rest,
  };
};