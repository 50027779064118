import { Modal } from '../Modal/Modal';
import {
  StyledConnectButton,
  StyledCountDownCount,
  StyledCountDownText,
  StyledCountDownWrapper,
  StyledText,
  StyledTitle,
  StyledUserEnds,
  StyledUserInfo,
  StyledUserInfoContents,
  StyledUserInfoImage,
  StyledUserInfoName,
  StyledUserInfoOccupation,
  StyledUserStarts,
  StyledWrapper
} from './CallModal.styled';
import { Content } from '../../Content/Content';
import { TranslationTypesEnum } from '../../../lib/types/translation-types-enum';
import { useEffect, useMemo, useState } from 'react';
import { useAppointments } from '../../../lib/hooks/use-appointments';
import { useCountdown } from 'usehooks-ts';
import { getAuth } from 'firebase/auth';
import Image from 'next/image';
import dayjs from 'dayjs';
import { useRouter } from '../../../lib/hooks/use-router';

type CallModalProps = {};

export const CallModal = ({}: CallModalProps) => {
  const { locale } = useRouter();
  const { nextAppointmentDetail } = useAppointments();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const diffInSeconds = (() => {
    if (!nextAppointmentDetail?.startTime) return 0;
    const startTime = dayjs(nextAppointmentDetail.startTime);
    return startTime.diff(dayjs(), 'second');
  })();

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: diffInSeconds,
    intervalMs: 1000,
  });

  const hasStarted = count <= 0;

  useEffect(() => {
    setIsModalOpen(!!nextAppointmentDetail);
    resetCountdown();
    startCountdown();
  }, [nextAppointmentDetail]);

  useEffect(() => {
    if (nextAppointmentDetail?.endTime) {
      const timer = setInterval(() => {
        if (dayjs().isAfter(dayjs(nextAppointmentDetail.endTime))) {
          setIsModalOpen(false);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [nextAppointmentDetail]);

  const user = useMemo(() => {
    const myUid = getAuth().currentUser?.uid;
    if (myUid === nextAppointmentDetail?.userId) {
      return nextAppointmentDetail?.specialist;
    }

    if (nextAppointmentDetail?.bookedFor) {
      return {
        ...nextAppointmentDetail?.bookedFor,
        title: '',
        credentials: '',
        photoUrl: '/images/placeholder/profile-photo.png'
      };
    }

    return nextAppointmentDetail?.user;
  }, [nextAppointmentDetail?.specialist, nextAppointmentDetail?.bookedFor, nextAppointmentDetail?.user]);

  const handleConnect = () => {
    window.open(`/consultation/${nextAppointmentDetail?.roomId}`, '_blank');
  };

  const closeModal = () => {
    // if (hasStarted) setIsModalOpen(false);
    setIsModalOpen(false);
  };

  const formatTime = (seconds: number): string => {
    if (seconds <= 0) return '00:00';
    if (seconds < 60) return `${seconds}s`;

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    return `${minutes}:${formattedSeconds}`;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      paperStyle={{
        overflow: 'hidden',
      }}
    >
      <StyledWrapper>
        <StyledTitle>
          {hasStarted ? (
            <Content id={'callModal.title.started'} type={TranslationTypesEnum.Main}/>
          ) : (
            <Content id={'callModal.title.starting'} type={TranslationTypesEnum.Main}/>
          )}
        </StyledTitle>
        {user && (
          <StyledUserInfo>
            <StyledUserInfoImage>
              <Image src={user.photoUrl || '/images/placeholder/profile-photo.png'} alt={user.firstName} fill/>
            </StyledUserInfoImage>
            <StyledUserInfoContents>
              <StyledUserInfoName>
                {user.title} {user.firstName} {user.lastName} {user.credentials}
              </StyledUserInfoName>
              <StyledUserInfoOccupation>
              </StyledUserInfoOccupation>
              <StyledUserStarts>
                <Content id={'callModal.text.starts'} type={TranslationTypesEnum.Main}/>{' '}
                {dayjs(nextAppointmentDetail?.startTime).format('HH:mm' + (locale === 'en' ? ' A' : ''))}
              </StyledUserStarts>
              <StyledUserEnds>
                <Content id={'callModal.text.ends'} type={TranslationTypesEnum.Main}/>{' '}
                {dayjs(nextAppointmentDetail?.endTime).format('HH:mm' + (locale === 'en' ? ' A' : ''))}
              </StyledUserEnds>
            </StyledUserInfoContents>
          </StyledUserInfo>
        )}
        <StyledText>
          {hasStarted ? (
            <Content id={'callModal.description.started'} type={TranslationTypesEnum.Main}/>
          ) : (
            <Content id={'callModal.description.starting'} type={TranslationTypesEnum.Main}/>
          )}
        </StyledText>
        {!hasStarted && (
          <StyledCountDownWrapper>
            <StyledCountDownText>
              <Content id={'callModal.text.starting'} type={TranslationTypesEnum.Main}/>
            </StyledCountDownText>
            <StyledCountDownCount>
              {count > 60 ? (
                formatTime(count)
              ) : (
                <>
                  {count} <Content id={'callModal.text.startingSeconds'} type={TranslationTypesEnum.Main}/>
                </>
              )}
            </StyledCountDownCount>
          </StyledCountDownWrapper>
        )}
        <StyledConnectButton onClick={handleConnect}>
          <Content id={'callModal.button.connect'} type={TranslationTypesEnum.Main}/>
        </StyledConnectButton>
      </StyledWrapper>
    </Modal>
  );
};
