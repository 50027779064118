import { StyledContent } from './Content.styled';
import React, { useMemo } from 'react';
import { useTranslation } from '../../lib/hooks/use-translation';
import { TranslationTypesEnum } from '../../lib/types/translation-types-enum';
import { StyledComponentProps } from '../../lib/styles/get-component-style';
import { useAppContext } from '../../context/AppProvider/AppProvider';
import { DEFAULT_TEXT_REPLACEMENTS } from './Content.constants';

type ContentProps = {
  id: string;
  type?: TranslationTypesEnum;
  contentStyle?: StyledComponentProps;
  placeholder?: string;
  replace?: ContentTextReplacementProps[];
};

export type ContentTextReplacementProps = {
  pattern: string;
  replacement: string;
  condition?: boolean;
};

export const Content = ({ id, type, contentStyle, replace = [], placeholder }: ContentProps) => {
  const { isEditing, setIsEditTranslationModalOpen, setTranslationProps, translationType } = useAppContext();
  type ||= translationType;
  replace = [...replace, ...DEFAULT_TEXT_REPLACEMENTS];
  const translationText = useTranslation(id, type);

  const handleEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isEditing) return;

    e.preventDefault();
    e.stopPropagation();

    setTranslationProps({ key: id, type });
    setIsEditTranslationModalOpen(true);
  };

  const text = useMemo(() => {
    if (!translationText) return placeholder;
    const text = translationText;
    if (!replace) return text;

    return replace.reduce((acc, { pattern, replacement, condition }) => {
      if (condition === false) return acc;
      return acc.replace(pattern, replacement);
    }, text);
  }, [translationText, replace, placeholder]);

  return (
    <StyledContent
      {...{ contentStyle, isEditing }}
      dangerouslySetInnerHTML={{ __html: text }}
      onClick={handleEdit}
    />
  );
};