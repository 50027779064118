import { useTranslationsQuery } from '../../services/translations/use-translations-query';
import { useIsMobile } from './use-is-mobile';
import { TranslationTypesEnum } from '../types/translation-types-enum';

export const useTranslation = (id: string, type: TranslationTypesEnum) => {
  const { data } = useTranslationsQuery(type);
  const isMobile = useIsMobile();

  if (isMobile) {
    return data?.[id + 'Mobile'] || data?.[id] || '';
  }

  return data?.[id] || '';
};