export const TRANSLATION_LANGUAGES = [
  {
    language: 'Slovak',
    locale: 'sk',
  },
  {
    language: 'English',
    locale: 'en',
  },
  {
    language: 'Czech',
    locale: 'cs',
  }
];