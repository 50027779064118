import { useEffect, useState } from 'react';
import { collection, DocumentData, onSnapshot, query, QuerySnapshot, where } from 'firebase/firestore';
import { Consultation } from '@vaza-eu/shoelessly';
import { db } from '../../../firebase';
import { getAuth } from 'firebase/auth';
import { useAppointmentQuery } from '../../services/appointments/common';
import dayjs from 'dayjs';

export const useAppointments = () => {
  const auth = getAuth();
  const uid = auth.currentUser?.uid;
  const [appointments, setAppointments] = useState<(Consultation & { id: string })[]>([]);
  const { data: nextAppointmentDetail } = useAppointmentQuery({ id: appointments?.[0]?.id });

  useEffect(() => {
    if (!uid) return;

    const specialistQuery = query(
      collection(db, 'appointments'),
      where('specialistId', '==', uid)
    );

    const userQuery = query(
      collection(db, 'appointments'),
      where('userId', '==', uid)
    );

    const handleSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
      setAppointments((prev) => {
        const newDocs = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as Consultation & { id: string };
        });

        const merged = [...prev, ...newDocs].reduce((map, doc) => {
          map.set(doc.id, doc);
          return map;
        }, new Map<string, Consultation & { id: string }>());

        const now = dayjs();
        const in180Seconds = now.add(180, 'second');

        const filteredArray = Array.from(merged.values()).filter(
          (doc) =>
            dayjs(doc.endTime).isAfter(now) &&
            dayjs(doc.startTime).isBefore(in180Seconds)
        );

        filteredArray.sort(
          (a, b) =>
            dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf()
        );

        return filteredArray;
      });
    };

    const unsubscribeSpecialist = onSnapshot(specialistQuery, handleSnapshot);
    const unsubscribeUser = onSnapshot(userQuery, handleSnapshot);

    return () => {
      unsubscribeSpecialist();
      unsubscribeUser();
    };
  }, [uid]);

  return { appointments, nextAppointmentDetail };
};
