import { useQuery } from 'react-query';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { TranslationProps } from '../../lib/types/translation-types-enum';
import { LANGUAGES } from '@vaza-eu/shoelessly';

export type SingleTranslationResponse = {
  sk: {
    [key: string]: string;
  };
  en: {
    [key: string]: string;
  };
  cs: {
    [key: string]: string;
  };
}

export const fetchSingleTranslation = async (type: TranslationProps['type']): Promise<SingleTranslationResponse | null> => {
  try {
    const docPromises = LANGUAGES.map(locale => getDoc(doc(db, 'translations', `${locale}-${type}`)));
    const docs = await Promise.all(docPromises);
    const translations: SingleTranslationResponse = {
      sk: {},
      en: {},
      cs: {},
    };

    docs.forEach(docSnap => {
      if (docSnap.exists()) {
        const [locale] = docSnap.id.split('-');
        translations[locale as keyof SingleTranslationResponse] = docSnap.data() as { [key: string]: string };
      }
    });

    return translations;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const useAllLanguagesTranslationsForTypeQuery = (type: TranslationProps['type']) => {
  return useQuery<SingleTranslationResponse | null>([`translations`, type], () => fetchSingleTranslation(type), {
    enabled: !!type,
  });
};
