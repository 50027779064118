import { useMutation, UseMutationResult } from 'react-query';
import { functions } from '../../../firebase';
import { httpsCallable } from 'firebase/functions';
import { SetTranslationRequest, SetTranslationResponse } from '@vaza-eu/shoelessly';

export const useTranslationsMutation = (): UseMutationResult<SetTranslationResponse, unknown, SetTranslationRequest> =>
  useMutation<SetTranslationResponse, unknown, SetTranslationRequest>(async (data: SetTranslationRequest) => {
    const cloudFn = httpsCallable<SetTranslationRequest, SetTranslationResponse>(functions, 'setTranslation');
    const response = await cloudFn(data);
    return response.data;
  });