import styled, { css } from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';
import { tabletMedia } from '@vaza-eu/vaza/dist/styles';
import { Button } from '../../UI/Button/Button';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  z-index: 100;

  * {
    font-family: Roboto, sans-serif;
  }

  @media (${tabletMedia}) {
    border-radius: 10px;
    width: 95%;
    margin: auto;
  }

  @media (${desktopMedia}) {
    width: 90%;
    max-width: 900px;
    min-height: 200px;
    padding: 30px 50px;
  }
`;

export const StyledTopPanelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (${desktopMedia}) {
    justify-content: space-between;
  }
`;

export const StyledTitle = styled.div`
  display: none;

  @media (${desktopMedia}) {
    display: block;
    color: black;
    font-size: 22px;
  }
`;

export const StyledEditorWrapper = styled.div`
  position: relative;
`;

export const StyledEditorCustomButtonsWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  display: flex;
  gap: 5px;

  @media (${desktopMedia}) {
    right: 10px;
    top: 8px;
    gap: 10px;
  }
`;

export const StyledEditorCustomButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 500;

  @media (${desktopMedia}) {
    font-size: 15px;
    width: 25px;
    height: 25px;
  }
`;

export const StyledLanguagesSelectWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  padding-top: 10px;
  width: 100%;

  @media (${desktopMedia}) {
    width: max-content;
`;

export const StyledLanguagesSelect = styled.button<{ isSelected: boolean }>`
  height: 28px;
  width: 100%;
  background-color: white;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
  border-top: 1px solid #EEEEEE;
  border-left: 1px solid #EEEEEE;
  border-right: 1px solid #EEEEEE;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: black;


  @media (${desktopMedia}) {
    width: 90px;
  }

  ${({ isSelected }) => isSelected && css`
    color: #26e126;
  `}
`;

export const StyledMobileEditorCheckBox = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMobileEditorCheckBoxLabel = styled.label`
  color: black;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 10px;
  justify-content: space-between;

  @media (${desktopMedia}) {
    justify-content: flex-end;
  }
`;

export const StyledCancelButton = styled(Button)<{ isSelected: boolean }>`
  color: white;
  background-color: #333;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 18px;
  height: 40px;
  width: 100%;

  @media (${desktopMedia}) {
    width: 100px;
  }

  ${({ isSelected }) => isSelected && css`
    background-color: blue;
  `}
`;

export const StyledSaveButton = styled(Button)<{ isSelected: boolean }>`
  color: white;
  background-color: #24af1c;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 18px;
  height: 40px;
  width: 100%;

  @media (${desktopMedia}) {
    width: 100px;
  }

  ${({ isSelected }) => isSelected && css`
    background-color: blue;
  `}
`;