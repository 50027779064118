import { useMutation, UseMutationResult } from 'react-query';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';

export const useCloudFunctionMutation = <Request, Response>(
  functionName: string
): UseMutationResult<Response, unknown, Request> => {
  return useMutation<Response, unknown, Request>(async (data: Request) => {
    const cloudFn = httpsCallable<Request, Response>(functions, functionName);
    const response = await cloudFn(data);
    return response.data;
  });
};